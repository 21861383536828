import urlParser from "js-video-url-parser";
import { Bloc } from "@bloc-js/bloc";
import { Channel, Socket } from "phoenix";
import { createContext } from "react";
import { IVideoState } from "../models/Room";
import { VideoCallBloc } from "./VideoCallBloc";
import { findWistiaID } from "../repo/messengerVideosRepo";
import { findVimeoID } from "../repo/studyGatewayVideosRepo";

export const VideoSharingContext = createContext<VideoSharingBloc | undefined>(
  undefined,
);

const validProviders = ["youtube", "vimeo", "wistia"];
export type TVideoSharingProvider = "youtube" | "vimeo" | "wistia";

export type TVideoSharingState = IVideoState &
  Readonly<{
    provider: TVideoSharingProvider;
    id: string;
  }>;

const initialState: TVideoSharingState = {
  url: null,
  visible: false,
  playing: false,
  show_participants: false,
  seek: 0,
  timestamp: Date.now(),
  id: "",
  provider: "youtube",
};

export class VideoSharingBloc extends Bloc<TVideoSharingState> {
  constructor(private videoCallBloc: VideoCallBloc) {
    super(initialState);

    this.subscribe(() => {
      this.syncToVideoCall();
    });
  }

  private channel?: Channel;
  private channelSubscription = 0;

  init(socket: Socket, roomID: number) {
    this.channel = socket.channel(`room:${roomID}`);
    this.channelSubscription = this.channel.on(
      "new_video_state",
      this.handleVideoState,
    );
    this.channel.join();
  }

  complete() {
    if (this.channel) {
      this.channel.off("new_video_state", this.channelSubscription);
      this.channel.leave();
    }

    super.complete();
  }

  private handleVideoState = (s: IVideoState) => {
    let nextState = { ...initialState };
    if (s.url) {
      if (s.url.startsWith("wistia")) {
        nextState = {
          ...s,
          provider: "wistia",
          id: s.url.split("/").pop()!,
        };
      } else {
        const info = urlParser.parse(s.url);
        if (validProviders.includes(info.provider)) {
          nextState = {
            ...s,
            provider: info.provider as TVideoSharingProvider,
            id: info.id,
          };
        }
      }
    }

    console.log("handleVideoState", nextState);
    this.next(nextState);
  };

  private syncToVideoCall() {
    const s = this.value;

    const videoFullscreen = s.visible && !s.show_participants;

    // Smart mute / unmute
    this.videoCallBloc.smartMute(s.playing, videoFullscreen).then(() => {
      this.videoCallBloc.smartUnmute(!s.playing, !videoFullscreen);
    });
  }

  share(url: string): Promise<void> {
    const channel = this.channel;
    if (!channel) return Promise.resolve();

    return Promise.resolve(url)
      .then((url) => {
        if (url.includes("messengercourses")) {
          return findWistiaID(url).then((id) => `wistia/${id}`);
        } else if (url.includes("studygateway")) {
          return findVimeoID(url).then(
            (id) => `https://player.vimeo.com/video/${id}`,
          );
        }

        const info = urlParser.parse(url);
        if (!validProviders.includes(info.provider)) {
          console.error("VideoSharingBloc", "Invalid provider");
          return Promise.reject(new Error("Invalid provider"));
        }

        return Promise.resolve(url);
      })
      .then((url) => {
        this.syncToChannel({
          ...this.value,
          url,
          playing: true,
          visible: true,
          seek: 0,
          timestamp: Date.now(),
        });
      });
  }

  private syncToChannel(s: IVideoState) {
    if (!this.channel) return;

    console.log("syncToChannel", s);
    this.channel.push("new_video_state", s);
  }

  pause(seek: number) {
    if (!this.value.url) return;
    this.syncToChannel({
      ...this.value,
      playing: false,
      seek,
      timestamp: Date.now(),
    });
  }

  play(seek: number) {
    if (!this.value.url) return;
    this.syncToChannel({
      ...this.value,
      playing: true,
      seek,
      timestamp: Date.now(),
    });
  }

  seek(seek: number) {
    if (!this.value.url) return;
    this.syncToChannel({
      ...this.value,
      seek,
      timestamp: Date.now(),
    });
  }

  hide() {
    if (!this.value.url) return;
    this.syncToChannel({
      ...this.value,
      playing: false,
      visible: false,
    });
  }

  show() {
    if (!this.value.url) return;
    this.syncToChannel({
      ...this.value,
      playing: true,
      visible: true,
    });
  }

  showParticipants() {
    if (!this.value.url) return;
    this.syncToChannel({
      ...this.value,
      show_participants: true,
    });
  }

  hideParticipants() {
    if (!this.value.url) return;
    this.syncToChannel({
      ...this.value,
      show_participants: false,
    });
  }

  stop() {
    if (!this.value.url) return;
    this.syncToChannel(initialState);
  }
}
