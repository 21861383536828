import * as tokens from "../repo/tokensRepo";
import { Bloc } from "@bloc-js/bloc";
import { create } from "../repo/roomsRepo";
import { IRoom } from "../models/Room";

export interface IHostRoomState {
  room?: IRoom;
  token?: string;
}

export class HostRoomBloc extends Bloc<IHostRoomState> {
  constructor() {
    super({});
  }

  createRoom(groupID?: number) {
    return create(groupID).then((room) =>
      this.next({
        ...this.value,
        room,
      }),
    );
  }

  fetchToken() {
    return tokens.me().then((token) =>
      this.next({
        ...this.value,
        token,
      }),
    );
  }
}
