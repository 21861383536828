import Chevron from "@material-ui/icons/ChevronRight";
import React, { FC } from "react";
import { FlocksGroup } from "../models/FlocksGroup";
import { RoundedList, RoundedListItem, Spacer } from "@arisechurch/design";

export interface IGroupListProps {
  className?: string;
  groups: FlocksGroup[];
  onClick: (group?: FlocksGroup) => void;
}

export const GroupList: FC<IGroupListProps> = ({
  className,
  groups,
  onClick,
}) => {
  return (
    <RoundedList className={className}>
      {groups.map((group) => (
        <RoundedListItem key={group.id} onClick={() => onClick(group)}>
          {group.name}
          <Spacer />
          <Chevron />
        </RoundedListItem>
      ))}
      <RoundedListItem onClick={() => onClick()}>
        Create meeting without a group
        <Spacer />
        <Chevron />
      </RoundedListItem>
    </RoundedList>
  );
};
