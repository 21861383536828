import React, { FC, useCallback, useContext } from "react";
import { HostToolbar } from "../components/HostToolbar";
import { IRoom, roomURL } from "../models/Room";
import { useBlocState } from "@bloc-js/react-bloc";
import { useToasts } from "react-toast-notifications";
import { VideoSharingContext } from "../bloc/VideoSharingBloc";
import { ShareDialogContext } from "../bloc/ShareDialogBloc";

export interface IHostToolbarContainerProps {
  room: IRoom;
}

export const HostToolbarContainer: FC<IHostToolbarContainerProps> = ({
  room,
}) => {
  // Logout
  const onClickSignOut = useCallback(() => {
    window.location.href = "/auth/logout";
  }, []);

  // Copy URL to clipboard
  const { addToast } = useToasts();
  const onClickCopyURL = useCallback(() => {
    const url = roomURL(room);
    if (navigator.clipboard) {
      navigator.clipboard.writeText(url);
    }
    addToast("Copied link to clipboard!", { appearance: "info" });
  }, [room, addToast]);

  // Share video
  const shareDialogBloc = useContext(ShareDialogContext)!;
  const videoSharingBloc = useContext(VideoSharingContext)!;
  const { url, visible, show_participants } = useBlocState(videoSharingBloc);
  const onClickShare = useCallback(() => {
    if (url) {
      videoSharingBloc.stop();
    } else {
      shareDialogBloc.open();
    }
  }, [videoSharingBloc, shareDialogBloc, url]);
  const onClickShowParticipants = useCallback(() => {
    if (!url) return;
    show_participants
      ? videoSharingBloc.hideParticipants()
      : videoSharingBloc.showParticipants();
  }, [videoSharingBloc, url, show_participants]);
  const onClickShowVideo = useCallback(() => {
    if (!url) return;
    visible ? videoSharingBloc.hide() : videoSharingBloc.show();
  }, [videoSharingBloc, url, visible]);

  return (
    <HostToolbar
      {...{
        room,
        participantsVisible: show_participants,
        videoShared: !!url,
        videoVisible: visible,
        onClickCopyURL,
        onClickShare,
        onClickSignOut,
        onClickShowParticipants,
        onClickShowVideo,
      }}
    />
  );
};
