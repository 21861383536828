import React, { FC, useEffect, useRef } from "react";
import { percent } from "csx";
import { useStyles } from "@tim-smart/react-typestyle";
import { VideoPlayerBloc } from "../bloc/VideoPlayerBloc";
import { VideoSharingBloc } from "../bloc/VideoSharingBloc";
import { withBlocs } from "@bloc-js/react-bloc";

export interface IVideoPlayerContainerProps {
  isHost: boolean;
  sharedVideoBloc: VideoSharingBloc;
}

export interface IVideoPlayerContainerBlocs {
  videoPlayerBloc: VideoPlayerBloc;
}

const VideoPlayerContainerI: FC<
  IVideoPlayerContainerProps & IVideoPlayerContainerBlocs
> = ({ isHost, sharedVideoBloc, videoPlayerBloc }) => {
  const divRef = useRef<HTMLDivElement>(null);

  // Bloc
  useEffect(() => {
    videoPlayerBloc.init(divRef.current!);
  }, [divRef, videoPlayerBloc, isHost, sharedVideoBloc]);

  const classNames = useStyles({
    root: {
      height: percent(100),
      width: percent(100),
      $nest: {
        iframe: {
          height: percent(100),
          width: percent(100),
        },
      },
    },
  });

  return <div ref={divRef} className={classNames.root}></div>;
};

export const VideoPlayerContainer = withBlocs<
  IVideoPlayerContainerProps,
  IVideoPlayerContainerBlocs
>(({ isHost, sharedVideoBloc }) => {
  const videoPlayerBloc = new VideoPlayerBloc(isHost, sharedVideoBloc);
  return {
    videoPlayerBloc,
  };
})(VideoPlayerContainerI);
