import { VideoPlayerAdapter } from "./VideoPlayerAdapter";

export class WistiaPlayerAdapter extends VideoPlayerAdapter {
  private w?: IWistiaPlayer;

  attach(el: HTMLElement, isHost: boolean, videoId: string, start: number) {
    el.setAttribute("id", `wistia_${videoId}`);

    this.w = Wistia.embed(videoId, {
      muted: false,
      playbar: isHost,
      time: start,
    });

    this.w.bind("play", () => {
      this.emit("playing");
    });

    this.w.bind("seek", () => {
      if (this.w!.state() === "playing") {
        this.emit("playing");
      }
    });

    this.w.bind("pause", () => {
      this.emit("paused");
    });

    this.w.bind("end", () => {
      this.emit("stopped");
    });
  }

  load(_id: string, _seek: number, autoStart: boolean) {
    this.w!.volume(1);
    this.w!.unmute();
    if (autoStart) {
      this.play();
    }
  }

  getCurrentTime(): Promise<number> {
    return Promise.resolve(this.w!.time());
  }

  async play() {
    this.w!.play();
  }

  async pause() {
    this.w!.pause();
  }

  async seek(time: number) {
    this.w!.time(time);
  }

  isPlaying() {
    return Promise.resolve(this.w!.state() === "playing");
  }

  destroy() {
    this.w!.remove();
  }
}
