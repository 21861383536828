import React, { FC } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Home } from "./pages/Home";
import { Host } from "./pages/Host";
import { HostRoom } from "./pages/HostRoom";
import { Room } from "./pages/Room";

export const AppRouter: FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact>
          <Home />
        </Route>

        <Route path="/rooms/:roomID" component={Room} />

        <Route path="/host" exact>
          <Host />
        </Route>

        <Route path="/host/:groupID" component={HostRoom} />
      </Switch>
    </BrowserRouter>
  );
};
