import React, { FC, useCallback } from "react";
import { em, percent, translate } from "csx";
import { Logo } from "../components/Logo";
import { colors, typography, Button, Spinner } from "@arisechurch/design";
import { useStyles } from "@tim-smart/react-typestyle";
import { useHistory } from "react-router-dom";
import { useBlocState, withBlocs } from "@bloc-js/react-bloc";
import { GroupListBloc } from "../bloc/GroupListBloc";
import { FlocksGroup } from "../models/FlocksGroup";
import { GroupList } from "../components/GroupList";

export interface IHostBlocs {
  groupListBloc: GroupListBloc;
}

const HostI: FC<IHostBlocs> = ({ groupListBloc }) => {
  const groups = useBlocState(groupListBloc);

  const history = useHistory();
  const onClick = useCallback(
    (group?: FlocksGroup) => {
      const id = group ? group.id : "no_group";
      history.push(`/host/${id}`);
    },
    [history],
  );

  const logout = useCallback(() => {
    window.location.href = "/auth/logout";
  }, []);

  const classNames = useStyles({
    root: {
      paddingBottom: em(2),
      paddingTop: em(2),
      textAlign: "center",
    },
    logo: {
      marginBottom: em(1.5),
      marginLeft: "auto",
      marginRight: "auto",
      maxWidth: percent(90),
    },

    header3: { ...typography.heading3, marginBottom: em(1) },

    list: {
      marginBottom: em(2),
      marginLeft: "auto",
      marginRight: "auto",
      maxWidth: percent(90),
      width: em(25),
    },

    spinner: {
      left: percent(50),
      position: "absolute",
      top: percent(50),
      transform: translate(percent(-50), percent(-50)),
    },
  });

  return (
    <div className={classNames.root}>
      {groups ? (
        <>
          <Logo className={classNames.logo} />

          <p className={classNames.header3}>Which group is meeting?</p>

          <div className={classNames.list}>
            <GroupList groups={groups} onClick={onClick} />
          </div>

          <Button onClick={logout}>Sign out</Button>
        </>
      ) : (
        <Spinner className={classNames.spinner} color={colors.white} />
      )}
    </div>
  );
};

export const Host = withBlocs<{}, IHostBlocs>(() => {
  const groupListBloc = new GroupListBloc(null);
  groupListBloc.fetch();
  return {
    groupListBloc,
  };
})(HostI);
