import React, { FC, useContext, useCallback, useEffect } from "react";
import { ShareDialog, TShareDialogShareType } from "../components/ShareDialog";
import { ShareDialogContext, TShareDialogTab } from "../bloc/ShareDialogBloc";
import { useBlocState, withBlocs } from "@bloc-js/react-bloc";
import { IResource } from "../models/Resource";
import { LiveVideosBloc } from "../bloc/LiveVideosBloc";

export interface IShareDialogContainerBlocs {
  liveVideosBloc: LiveVideosBloc;
}

const ShareDialogContainerI: FC<IShareDialogContainerBlocs> = ({
  liveVideosBloc,
}) => {
  const bloc = useContext(ShareDialogContext)!;
  const state = useBlocState(bloc);
  const openState = state.type === "open" ? state : undefined;

  // Live videos
  useEffect(() => {
    let fetched = false;
    const s = bloc.subscribe(({ type }) => {
      if (type === "open" && !fetched) {
        liveVideosBloc.fetch();
        fetched = true;
      } else if (type !== "open") {
        fetched = false;
      }
    });
    return () => {
      s.unsubscribe();
    };
  }, [liveVideosBloc, bloc]);
  const liveVideos = useBlocState(liveVideosBloc);

  const onClose = useCallback(() => {
    bloc.close();
  }, [bloc]);

  const onTabSelect = useCallback(
    (tab: TShareDialogTab) => {
      bloc.changeTab(tab);
    },
    [bloc],
  );

  const onQueryUpdate = useCallback(
    (q: string) => {
      bloc.updateQuery(q);
    },
    [bloc],
  );

  const onShare = useCallback(
    (type: TShareDialogShareType, r?: IResource) => {
      const liveVideos = liveVideosBloc.value;
      if (type === "live") {
        if (liveVideos) {
          bloc.share(
            `https://www.youtube.com/watch?v=${liveVideos[0].id.videoId}`,
          );
        }
      } else if (r) {
        bloc.share(r.video);
      } else {
        bloc.share();
      }
    },
    [bloc, liveVideosBloc],
  );

  return (
    <ShareDialog
      tab={state.type === "open" ? state.tab : "start"}
      open={state.type === "open"}
      resources={openState && openState.resources}
      loading={state.type === "open" && state.loading}
      hasLiveVideo={!!(liveVideos && liveVideos.length)}
      onClose={onClose}
      onTabSelect={onTabSelect}
      onQueryUpdate={onQueryUpdate}
      onShare={onShare}
    />
  );
};

export const ShareDialogContainer = withBlocs<{}, IShareDialogContainerBlocs>(
  () => {
    const liveVideosBloc = new LiveVideosBloc();
    return {
      liveVideosBloc,
    };
  },
)(ShareDialogContainerI);
