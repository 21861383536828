import { Bloc } from "@bloc-js/bloc";
import { isMobile } from "../utils/isMobile";
import {
  TVideoSharingState,
  VideoSharingBloc,
  TVideoSharingProvider,
} from "./VideoSharingBloc";
import { VimeoPlayerAdapter } from "../videoAdapters/VimeoPlayerAdapter";
import { YoutubePlayerAdapter } from "../videoAdapters/YoutubePlayerAdapter";
import { VideoPlayerAdapter } from "../videoAdapters/VideoPlayerAdapter";
import { WistiaPlayerAdapter } from "../videoAdapters/WistiaPlayerAdapter";

type TVideoPlayerState = boolean;

export class VideoPlayerBloc extends Bloc<TVideoPlayerState> {
  constructor(private isHost: boolean, private videoSharing: VideoSharingBloc) {
    super(false);

    this.unsubscribeOnComplete(
      videoSharing.subscribe((s) => {
        this.sync(s);
      }),
    );
  }

  private waitingForPlay = isMobile();
  private player?: VideoPlayerAdapter;

  actualSeek(s: TVideoSharingState) {
    const diff = (Date.now() - s.timestamp) / 1000;
    return s.seek + diff;
  }

  private getAdapter(provider: TVideoSharingProvider) {
    switch (provider) {
      case "vimeo":
        return new VimeoPlayerAdapter();
      case "youtube":
        return new YoutubePlayerAdapter();
      case "wistia":
        return new WistiaPlayerAdapter();
    }
  }

  init(el: HTMLElement) {
    const s = this.videoSharing.value;

    this.player = this.getAdapter(s.provider);
    if (!this.player) {
      return this.next(false);
    }

    this.player.attach(el, this.isHost, s.id, this.actualSeek(s));
    this.player.load(
      s.id,
      this.actualSeek(s),
      s.playing && !this.waitingForPlay,
    );

    if (this.isHost) {
      this.setupHostListeners(this.player);
    } else {
      this.setupWatcherListeners(this.player);
    }

    this.sync(this.videoSharing.value, true);

    if (this.waitingForPlay) {
      this.waitForFirstPlay();
    }
  }

  private sync(s: TVideoSharingState, init = false) {
    if (!this.player) return;
    const p = this.player;

    if (this.isHost) {
      p.isPlaying().then((isPlaying) => {
        console.log("SYNC", isPlaying, s.playing);
        if (isPlaying && !s.playing) {
          p.pause();
        } else if (!isPlaying && s.playing) {
          p.play();
        }
      });
      return;
    }

    if (s.playing) {
      if (!init || !this.waitingForPlay) {
        p.seek(this.actualSeek(s)).then(() => {
          p.play();
        });
      }
    } else {
      p.pause();
    }
  }

  private waitForFirstPlay() {
    const p = this.player!;
    p.once("playing", () => {
      this.waitingForPlay = false;
      p.seek(this.actualSeek(this.videoSharing.value));
    });
  }

  private setupHostListeners(p: VideoPlayerAdapter) {
    p.on("playing", this.onPlaying);
    p.on("paused", this.onPaused);
    p.on("stopped", this.onStopped);
  }

  private setupWatcherListeners(p: VideoPlayerAdapter) {
    p.on("paused", this.onPaused);
  }

  private onPlaying = () => {
    const p = this.player!;
    p.getCurrentTime().then((time) => {
      this.videoSharing.play(time);
    });
  };
  private onPaused = () => {
    const p = this.player!;
    if (this.isHost) {
      p.getCurrentTime().then((time) => {
        this.videoSharing.pause(time);
      });
    } else {
      p.once("playing", () => {
        p.seek(this.actualSeek(this.videoSharing.value));
      });
    }
  };
  private onStopped = () => {
    this.videoSharing.stop();
  };

  complete() {
    this.player?.destroy();
    super.complete();
  }
}
