import React, { FC, useEffect, useRef } from "react";

export interface IVideoCallProps {
  interfaceConfig: Partial<JitsiInterfaceConfig>;
  roomName: string;
  onApiReady: (api: JitsiMeetExternalAPI) => void;
}

export const VideoCall: FC<IVideoCallProps> = ({
  roomName,
  onApiReady,
  interfaceConfig,
}) => {
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!divRef.current) return;

    const api = new JitsiMeetExternalAPI("meet.jit.si", {
      interfaceConfigOverwrite: interfaceConfig,
      parentNode: divRef.current,
      roomName,
    });

    onApiReady(api);

    return () => {
      api.dispose();
    };
  }, [divRef, interfaceConfig, roomName, onApiReady]);

  return <div ref={divRef} style={{ height: "100%", width: "100%" }}></div>;
};
