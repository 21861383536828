import React, { FC, ReactElement, ChangeEvent, useCallback } from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  DialogContent,
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  ChevronRight,
  Videocam,
  MovieFilter,
  LiveTv,
} from "@material-ui/icons";
import { Button, TextField, colors, Spinner } from "@arisechurch/design";
import { TShareDialogTab } from "../bloc/ShareDialogBloc";
import { IResource } from "../models/Resource";
import { useStyles } from "@tim-smart/react-typestyle";
import { percent, rem, em } from "csx";
import { NestedCSSProperties } from "typestyle/lib/types";

interface IShareListItemProps {
  icon?: ReactElement;
  primary: string;
  secondary?: string;
  disabled?: boolean;
  onClick: () => void;
}

const ShareListItem: FC<IShareListItemProps> = ({
  icon = <Videocam />,
  primary,
  secondary,
  disabled = false,
  onClick,
}) => {
  return (
    <ListItem button onClick={onClick} disabled={disabled}>
      <ListItemAvatar>
        <Avatar>{icon}</Avatar>
      </ListItemAvatar>
      <ListItemText primary={primary} secondary={secondary} />
      <ChevronRight />
    </ListItem>
  );
};

interface IResourceListItemProps {
  resource: IResource;
  onClick: () => void;
}

const ResourceListItem: FC<IResourceListItemProps> = ({
  resource,
  onClick,
}) => {
  const ResourceListItemText = withStyles({
    primary: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  })(ListItemText);
  return (
    <ListItem button onClick={onClick}>
      <ResourceListItemText
        primary={resource.title}
        secondary={resource.root_resource && resource.root_resource.title}
      />
      <ChevronRight />
    </ListItem>
  );
};

export type TShareDialogShareType = "resource" | "url" | "live";

export interface IShareDialogProps {
  open: boolean;
  tab: TShareDialogTab;
  hasLiveVideo: boolean;

  resources?: IResource[];
  loading: boolean;

  onClose: () => void;
  onTabSelect: (tab: TShareDialogTab) => void;
  onQueryUpdate: (query: string) => void;
  onShare: (type: TShareDialogShareType, resource?: IResource) => void;
}

export const ShareDialog: FC<IShareDialogProps> = ({
  open,
  tab,
  loading,
  resources,
  hasLiveVideo,

  onClose,
  onTabSelect,
  onQueryUpdate,
  onShare,
}) => {
  // Handle text update
  const onInputChange = useCallback(
    (c: ChangeEvent<HTMLInputElement>) => {
      onQueryUpdate(c.target.value);
    },
    [onQueryUpdate],
  );

  // Styles
  const tabCount = 2;
  const classNames = useStyles({
    sliderWrap: {
      overflow: "hidden",
      width: percent(100),
    } as NestedCSSProperties,
    slider: {
      alignItems: "stretch",
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      transition: "transform 300ms",
      width: percent(100 * tabCount),
    } as NestedCSSProperties,
    tab: {
      width: percent(100 / tabCount),
    },
    spinner: {
      marginTop: em(2),
      textAlign: "center",
    } as NestedCSSProperties,
  });

  // Translate
  const translate = tab === "start" ? percent(0) : percent(-50);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth scroll="body">
      <DialogTitle>Share Resources</DialogTitle>

      <div className={classNames.sliderWrap}>
        <div
          className={classNames.slider}
          style={{ transform: `translateX(${translate})` }}
        >
          <List className={classNames.tab}>
            <ShareListItem
              onClick={() => onTabSelect("resources")}
              primary="ARISE Resources"
            />
            <ShareListItem
              onClick={() => onTabSelect("url")}
              icon={<MovieFilter />}
              primary="Youtube or Vimeo URL"
            />
            <ShareListItem
              onClick={() => onShare("live")}
              icon={<LiveTv />}
              primary="ARISE Online"
              secondary={
                hasLiveVideo
                  ? "Church is live! Click here to watch."
                  : "Currently Online Church is not live"
              }
              disabled={!hasLiveVideo}
            />
          </List>

          {tab === "resources" ? (
            <div className={classNames.tab}>
              <DialogContent>
                <TextField label="Search" onChange={onInputChange} fullWidth />
              </DialogContent>

              {loading ? (
                <Spinner
                  className={classNames.spinner}
                  size={rem(1.5).toString()}
                />
              ) : (
                resources && (
                  <List>
                    {resources.map((resource) => (
                      <ResourceListItem
                        key={resource.id}
                        resource={resource}
                        onClick={() => onShare("resource", resource)}
                      />
                    ))}
                  </List>
                )
              )}
            </div>
          ) : (
            <DialogContent>
              <TextField label="Video URL" onChange={onInputChange} fullWidth />
            </DialogContent>
          )}
        </div>
      </div>

      <DialogActions>
        <Button onClick={onClose} size="small">
          Close
        </Button>
        {!["start", "resources"].includes(tab) && (
          <Button
            size="small"
            onClick={() => onShare("url")}
            color={colors.primary}
          >
            Share
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
