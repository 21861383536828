import { Bloc } from "@bloc-js/bloc";
import { FlocksGroup } from "../models/FlocksGroup";
import { createContext } from "react";
import { deepDistinct } from "../utils/deepDistinct";

export const VideoCallContext = createContext<VideoCallBloc | undefined>(
  undefined,
);

export interface IVideoCallState {
  previouslyMuted: boolean;
  muted: boolean;
  previouslyVideoMuted: boolean;
  videoMuted: boolean;
}

export class VideoCallBloc extends Bloc<IVideoCallState> {
  constructor(private isHost: boolean) {
    super({
      previouslyMuted: false,
      muted: false,
      previouslyVideoMuted: false,
      videoMuted: false,
    });

    this.pipe(deepDistinct()).subscribe(() => {
      this.syncStateToAPI();
    });
  }

  private api?: JitsiMeetExternalAPI;
  private group?: FlocksGroup;

  init(api: JitsiMeetExternalAPI, group?: FlocksGroup) {
    this.api = api;
    this.group = group;

    // Always start in tile view
    let toggled = false;
    this.api.on("tileViewChanged", ({ enabled }) => {
      if (toggled) return;
      if (enabled) {
        toggled = true;
        return this.api!.removeAllListeners("toggleTileView");
      }
      this.api!.executeCommand("toggleTileView");
    });
    this.api.once("participantJoined", () => {
      this.api!.executeCommand("toggleTileView");
    });

    // Set subject if host
    if (this.isHost && this.group) {
      this.api.executeCommand("subject", this.group.name);
    }

    this.syncStateToAPI();
  }

  smartMute(audio = true, video = false) {
    if (!this.api) return Promise.resolve();

    let nextState = this.value;

    return Promise.all([
      this.api!.isAudioMuted(),
      this.api!.isVideoMuted(),
    ]).then(([audioMuted, videoMuted]) => {
      if (audio && !audioMuted) {
        nextState = {
          ...nextState,
          previouslyMuted: audioMuted,
          muted: true,
        };
      }
      if (video && !videoMuted) {
        nextState = {
          ...nextState,
          previouslyVideoMuted: videoMuted,
          videoMuted: true,
        };
      }

      this.next(nextState);
    });
  }

  smartUnmute(audio = true, video = false) {
    if (!this.api) return Promise.resolve();

    let nextState = this.value;

    console.log("smartUnmute", audio, video, this.value);

    return Promise.all([this.api.isAudioMuted(), this.api.isVideoMuted()]).then(
      ([audioMuted, videoMuted]) => {
        if (audio && audioMuted) {
          nextState = {
            ...nextState,
            muted: this.value.previouslyMuted,
          };
        }

        if (video && videoMuted) {
          nextState = {
            ...nextState,
            videoMuted: this.value.previouslyVideoMuted,
          };
        }

        this.next(nextState);
      },
    );
  }

  private syncStateToAPI() {
    const api = this.api;
    if (!api) return;

    const { muted: shouldMute, videoMuted: shouldMuteVideo } = this.value;

    return Promise.all([
      this.api!.isAudioMuted(),
      this.api!.isVideoMuted(),
    ]).then(([audioMuted, videoMuted]) => {
      if ((shouldMute && !audioMuted) || (!shouldMute && audioMuted)) {
        api.executeCommand("toggleAudio");
      }

      if (
        (shouldMuteVideo && !videoMuted) ||
        (!shouldMuteVideo && videoMuted)
      ) {
        api.executeCommand("toggleVideo");
      }
    });
  }
}
