import * as serviceWorker from "./serviceWorker";
import React from "react";
import ReactDOM from "react-dom";
import { AppRouter } from "./router";
import { colors, Providers } from "@arisechurch/design";
import { percent } from "csx";
import { setupPage } from "csstips";
import { style } from "typestyle";
import "@arisechurch/design/src/global.css";

require("./index.css");

setupPage("#root");

ReactDOM.render(
  <React.StrictMode>
    <Providers>
      <div
        className={style({
          color: colors.white.toString(),
          height: percent(100),
          overflow: "auto",
          width: percent(100),
        })}
      >
        <AppRouter />
      </div>
    </Providers>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
