import React, { FC } from "react";
import { percent, translateY, viewHeight } from "csx";
import { NestedCSSProperties } from "typestyle/lib/types";
import { useStyles } from "@tim-smart/react-typestyle";
import { useBreakpoint } from "@arisechurch/design";

export interface IVideoSharingOverlayProps {
  enabled: boolean;
  showParticipants: boolean;
  visible: boolean;
}

export const VideoSharingOverlay: FC<IVideoSharingOverlayProps> = ({
  enabled,
  showParticipants: maybeShowParticipants,
  visible,
  children,
}) => {
  const breakpoint = useBreakpoint();
  const isSmall = breakpoint === "small" || breakpoint === "smallPlus";
  const showParticipants = maybeShowParticipants && !isSmall;

  const classNames = useStyles({
    root: {
      height: percent(100),
      left: 0,
      position: "absolute",
      top: 0,
      transition: "opacity 300ms ease-in-out",
      width: percent(100),
      willChange: ["opacity", "pointerEvents"],
      zIndex: 1,
    } as NestedCSSProperties,

    rootWithParticipants: {
      flexShrink: 0,
      height: percent(100),
      transition: "opacity 300ms ease-in-out",
      width: percent(75),
      willChange: ["opacity", "pointerEvents"],
    } as NestedCSSProperties,

    video: {
      height: percent(100),
      position: "relative",
      transition: "transform 300ms ease-in-out",
      width: percent(100),
      willChange: "transform",
    },
  });

  return enabled ? (
    <div
      className={
        showParticipants ? classNames.rootWithParticipants : classNames.root
      }
      style={{
        opacity: visible ? 1 : 0,
        pointerEvents: visible ? "all" : "none",
        width: showParticipants && !visible ? percent(0) : undefined,
      }}
    >
      <div
        className={classNames.video}
        style={{
          transform: visible ? translateY(0) : translateY(viewHeight(-20)),
        }}
      >
        {children}
      </div>
    </div>
  ) : (
    <div></div>
  );
};
