function throwErrors(r: any) {
  if (r.errors) {
    throw r;
  }

  return r;
}

export function search(query: string) {
  const url = new URL(window.location.href);
  url.pathname = "/api/resources";
  url.search = new URLSearchParams({ query }).toString();
  return fetch(url.toString())
    .then((r) => r.json())
    .then(throwErrors)
    .then((r) => r.data);
}
