import React, { FC, useCallback } from "react";
import { Button, colors } from "@arisechurch/design";
import { em, percent } from "csx";
import { Logo } from "../components/Logo";
import { useStyles } from "@tim-smart/react-typestyle";

export const Home: FC = () => {
  const classNames = useStyles({
    root: {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      height: percent(100),
      justifyContent: "center",
      width: percent(100),
    },

    logo: {
      marginBottom: em(1.5),
      maxWidth: percent(90),
    },
  });

  const onClick = useCallback(() => {
    window.location.href = "/host";
  }, []);

  return (
    <div className={classNames.root}>
      <Logo className={classNames.logo} />

      <Button
        size="large"
        color={colors.white}
        textColor={colors.secondary}
        onClick={onClick}
      >
        Create Online Meeting
      </Button>
    </div>
  );
};
