import { Bloc } from "@bloc-js/bloc";
import { createContext } from "react";
import { IResource } from "../models/Resource";
import { search } from "../repo/resourcesRepo";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";

export const ShareDialogContext = createContext<ShareDialogBloc | undefined>(
  undefined,
);

export type TShareDialogTab = "start" | "resources" | "url";

export type TShareDialogState =
  | IShareDialogStateClosed
  | IShareDialogStateOpen
  | IShareDialogStateShared;

interface IShareDialogStateClosed {
  type: "closed";
}

interface IShareDialogStateOpen {
  type: "open";
  tab: TShareDialogTab;

  query?: string;
  resources?: IResource[];
  loading: boolean;
}

interface IShareDialogStateShared {
  type: "shared";
  url: string;
}

const initialState: TShareDialogState = {
  type: "closed",
};

export class ShareDialogBloc extends Bloc<TShareDialogState> {
  constructor() {
    super(initialState);

    this.search$.pipe(debounceTime(500)).subscribe((query) => {
      this.search(query);
    });
  }

  private search$ = new Subject<string>();

  open() {
    this.next({
      type: "open",
      tab: "start",
      loading: false,
    });
  }

  changeTab(tab: TShareDialogTab) {
    if (this.value.type !== "open") return;
    this.next({
      ...this.value,
      tab,
    });
  }

  updateQuery(query: string) {
    if (this.value.type !== "open") return;

    this.next({
      ...this.value,
      query,
    });

    if (this.value.tab === "resources") {
      this.search$.next(query);
    }
  }

  search(query: string) {
    if (this.value.type !== "open") return;

    if (!query) {
      return this.next({
        ...this.value,
        resources: undefined,
      });
    }

    this.next({
      ...this.value,
      loading: true,
      resources: undefined,
    });

    search(query)
      .then(
        (resources: IResource[]) =>
          this.value.type === "open" &&
          this.next({
            ...this.value,
            resources: resources.filter((r) => !!r.video),
          }),
        (err) => console.error(err),
      )
      .finally(
        () =>
          this.value.type === "open" &&
          this.next({
            ...this.value,
            loading: false,
          }),
      );
  }

  share(url?: string) {
    if (this.value.type !== "open") return;
    const { query, tab } = this.value;

    if (url) {
      this.next({
        type: "shared",
        url,
      });
    } else if (query && tab === "url") {
      this.next({
        type: "shared",
        url: query,
      });
    } else {
      this.close();
    }
  }

  close() {
    this.next({
      type: "closed",
    });
  }
}
