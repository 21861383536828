import React, { FC, useEffect, useRef } from "react";
import { colors, Spinner } from "@arisechurch/design";
import { createSocket } from "../socket";
import { fullScreenFlex } from "../styles/layout";
import { percent } from "csx";
import { RoomBloc } from "../bloc/RoomBloc";
import { useBlocState, withBlocs } from "@bloc-js/react-bloc";
import { useParams, RouteComponentProps } from "react-router-dom";
import { useStyles } from "@tim-smart/react-typestyle";
import { VideoCallBloc, VideoCallContext } from "../bloc/VideoCallBloc";
import { VideoCallContainer } from "../containers/VideoCallContainer";
import { VideoSharingContainer } from "../containers/VideoSharingContainer";
import {
  VideoSharingBloc,
  VideoSharingContext,
} from "../bloc/VideoSharingBloc";

interface IRoomBlocs {
  roomBloc: RoomBloc;
  videoCallBloc: VideoCallBloc;
  videoSharingBloc: VideoSharingBloc;
}

interface IRoomProps extends RouteComponentProps<{ roomID: string }> {}

const RoomI: FC<IRoomProps & IRoomBlocs> = ({
  roomBloc,
  videoCallBloc,
  videoSharingBloc,
}) => {
  const { roomID } = useParams<{ roomID: string }>();

  // Room bloc
  const room = useBlocState(roomBloc);

  // Create socket
  const socketRef = useRef(createSocket());
  useEffect(() => {
    const socket = socketRef.current;
    socket.connect();
    return () => {
      socket.disconnect();
    };
  }, [socketRef]);

  // Video sharing bloc init
  useEffect(() => {
    if (!room) return;
    videoSharingBloc.init(socketRef.current, room.id);
  }, [videoSharingBloc, room]);

  // Styles
  const classNames = useStyles({
    root: {
      ...fullScreenFlex,
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
    },

    video: {
      display: "flex",
      flex: 1,
      position: "relative",
      width: percent(100),
    },
  });

  return (
    <div className={classNames.root}>
      {room ? (
        <VideoCallContext.Provider value={videoCallBloc}>
          <VideoSharingContext.Provider value={videoSharingBloc}>
            <div className={classNames.video}>
              <VideoSharingContainer isHost={false} />
              <VideoCallContainer
                roomName={roomID}
                interfaceConfig={{
                  DEFAULT_BACKGROUND: colors.text.toString(),
                  HIDE_KICK_BUTTON_FOR_GUESTS: true,
                  TOOLBAR_BUTTONS: [
                    "microphone",
                    "camera",
                    "fullscreen",
                    "fodeviceselection",
                    "hangup",
                    "chat",
                    "settings",
                    "videoquality",
                    "tileview",
                    "help",
                  ],
                  SHOW_CHROME_EXTENSION_BANNER: false,
                  SETTINGS_SECTIONS: ["devices"],
                }}
              />
            </div>
          </VideoSharingContext.Provider>
        </VideoCallContext.Provider>
      ) : (
        <Spinner color={colors.white} />
      )}
    </div>
  );
};

export const Room = withBlocs<IRoomProps, IRoomBlocs>((props) => {
  const {
    match: {
      params: { roomID },
    },
  } = props;

  const roomBloc = new RoomBloc();
  roomBloc.fetch(roomID);

  const videoCallBloc = new VideoCallBloc(false);
  const videoSharingBloc = new VideoSharingBloc(videoCallBloc);

  return {
    roomBloc,
    videoCallBloc,
    videoSharingBloc,
  };
})(RoomI);
