import { Bloc } from "@bloc-js/bloc";
import { get } from "../repo/roomsRepo";
import { IRoom } from "../models/Room";

export class RoomBloc extends Bloc<IRoom | null> {
  constructor() {
    super(null);
  }

  fetch(roomID: string) {
    return get(roomID).then((room) => this.next(room));
  }
}
