import Vimeo from "@vimeo/player";
import { VideoPlayerAdapter } from "./VideoPlayerAdapter";

export class VimeoPlayerAdapter extends VideoPlayerAdapter {
  private v?: Vimeo;

  attach(el: HTMLElement, _isHost: boolean, videoId: string, _start: number) {
    this.v = new Vimeo(el, {
      id: +videoId,
      // controls: isHost,
    });

    this.v.on("ended", () => {
      this.emit("stopped");
    });
    this.v.on("play", () => {
      this.emit("playing");
    });
    this.v.on("pause", () => {
      this.emit("paused");
    });
  }

  load(_id: string, seek: number, autoStart: boolean) {
    this.seek(seek);
    this.v!.setVolume(1);
    if (autoStart) {
      this.play();
    }
  }

  getCurrentTime(): Promise<number> {
    return this.v!.getCurrentTime();
  }

  play() {
    return this.v!.play().catch(console.error);
  }

  pause() {
    return this.v!.pause().catch(console.error);
  }

  seek(time: number) {
    return this.v!.setCurrentTime(time).then(() => {}, console.error);
  }

  isPlaying(): Promise<boolean> {
    return this.v!.getPaused().then((isPaused) => !isPaused);
  }

  destroy() {
    this.v!.destroy();
  }
}
