import { IRoom } from "../models/Room";

export const create = (groupID?: number): Promise<IRoom> => {
  return fetch("/api/rooms", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      room: {
        flocks_group_id: groupID,
      },
    }),
  })
    .then((r) => r.json())
    .then((r) => r["data"]);
};

export const get = (roomID: string): Promise<IRoom> => {
  return fetch(`/api/rooms/${roomID}`)
    .then((r) => r.json())
    .then((r) => r["data"]);
};
