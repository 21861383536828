import { Bloc } from "@bloc-js/bloc";
import { FlocksGroup } from "../models/FlocksGroup";
import { list } from "../repo/groupsRepo";

export class GroupListBloc extends Bloc<FlocksGroup[] | null> {
  fetch() {
    return list()
      .then((groups) => this.next(groups))
      .catch((err) => this.error(err));
  }
}
