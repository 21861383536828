import SEE from "strict-event-emitter-types";
import { EventEmitter } from "events";

export interface IVideoPlayerEvents {
  playing: void;
  paused: void;
  stopped: void;
}

export type TVideoPlayerEmitter = SEE<EventEmitter, IVideoPlayerEvents>;

export abstract class VideoPlayerAdapter extends (EventEmitter as {
  new (): TVideoPlayerEmitter;
}) {
  abstract attach(
    el: HTMLElement,
    isHost: boolean,
    videoId: string,
    start: number,
  ): void;
  abstract load(id: string, seek: number, autoStart: boolean): void;
  abstract play(): Promise<void>;
  abstract pause(): Promise<void>;
  abstract seek(time: number): Promise<void>;
  abstract getCurrentTime(): Promise<number>;
  abstract isPlaying(): Promise<boolean>;
  abstract destroy(): void;
}
