import React, { FC, useCallback, useContext } from "react";
import { FlocksGroup } from "../models/FlocksGroup";
import { VideoCall } from "../components/VideoCall";
import { VideoCallContext } from "../bloc/VideoCallBloc";

export interface IVideoCallContainerProps {
  group?: FlocksGroup;
  interfaceConfig: Partial<JitsiInterfaceConfig>;
  roomName: string;
}

export const VideoCallContainer: FC<IVideoCallContainerProps> = ({
  group,
  interfaceConfig,
  roomName,
}) => {
  const bloc = useContext(VideoCallContext)!;

  const onApiReady = useCallback(
    (api: JitsiMeetExternalAPI) => {
      bloc.init(api, group);
    },
    [bloc, group],
  );

  return (
    <VideoCall
      roomName={roomName}
      interfaceConfig={interfaceConfig}
      onApiReady={onApiReady}
    />
  );
};
