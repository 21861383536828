import React, { FC } from "react";
import { Button, colors, Spacer, typography } from "@arisechurch/design";
import { em, percent } from "csx";
import { IRoom } from "../models/Room";
import { Logo } from "./Logo";
import { padding } from "csstips";
import { useStyles } from "@tim-smart/react-typestyle";

export interface IHostToolbarProps {
  room: IRoom;

  participantsVisible: boolean;
  videoShared: boolean;
  videoVisible: boolean;

  onClickCopyURL: () => void;
  onClickSignOut: () => void;
  onClickShare: () => void;
  onClickShowParticipants: () => void;
  onClickShowVideo: () => void;
}

export const HostToolbar: FC<IHostToolbarProps> = ({
  participantsVisible,
  videoShared,
  videoVisible,

  onClickCopyURL,
  onClickSignOut,
  onClickShare,
  onClickShowParticipants,
  onClickShowVideo,
}) => {
  // Styles
  const classNames = useStyles({
    root: {
      ...padding(em(0.5)),
      alignItems: "center",
      display: "flex",
      flexDirection: "row",
      width: percent(100),

      $nest: {
        button: {
          marginRight: em(1),

          $nest: {
            "&:last-child": {
              marginRight: 0,
            },
          },
        },
      },
    },

    logo: {
      ...typography.heading4,
      display: "inline-block",
      marginBottom: 0,
      marginRight: em(1),
    },
  });

  return (
    <div className={classNames.root}>
      <Logo className={classNames.logo} size={8} horizontal />

      <Button
        color={colors.white}
        textColor={colors.secondary}
        size="small"
        onClick={onClickCopyURL}
      >
        Copy Link to Share Meeting
      </Button>

      <Button
        color={colors.white}
        textColor={colors.secondary}
        size="small"
        onClick={onClickShare}
      >
        {videoShared ? "Stop Sharing" : "Share Resource"}
      </Button>

      {videoShared && (
        <>
          <Button
            color={colors.white}
            textColor={colors.secondary}
            size="small"
            onClick={onClickShowVideo}
          >
            {videoVisible ? "Hide & Pause" : "Show"} Video
          </Button>

          <Button
            color={colors.white}
            textColor={colors.secondary}
            size="small"
            onClick={onClickShowParticipants}
          >
            {participantsVisible ? "Hide" : "Show"} Participants
          </Button>
        </>
      )}

      <Spacer />

      <Button size="small" onClick={onClickSignOut}>
        Sign out
      </Button>
    </div>
  );
};
