import YT from "youtube-player";
import { YouTubePlayer } from "youtube-player/dist/types";
import { VideoPlayerAdapter } from "./VideoPlayerAdapter";
import PlayerStates from "youtube-player/dist/constants/PlayerStates";

export class YoutubePlayerAdapter extends VideoPlayerAdapter {
  private yt?: YouTubePlayer;

  attach(el: HTMLElement, isHost: boolean, videoId: string, start: number) {
    this.yt = YT(el, {
      videoId,
      playerVars: {
        controls: isHost ? 1 : 0,
        start,
        autoplay: 0,
      },
    });

    this.yt.on("stateChange", (e: any) => {
      switch (e.data as number) {
        case 0:
          this.emit("stopped");
          break;

        case 1:
          this.emit("playing");
          break;

        case 2:
          this.emit("paused");
          break;
      }
    });
  }

  load(_id: string, _seek: number, autoStart: boolean) {
    this.yt!.setVolume(100);

    if (autoStart) {
      this.play();
    }
  }

  getCurrentTime(): Promise<number> {
    return this.yt!.getCurrentTime() as any;
  }

  async play() {
    this.yt!.playVideo();
  }

  async pause() {
    this.yt!.pauseVideo();
  }

  async seek(time: number) {
    this.yt!.seekTo(time, true);
  }

  isPlaying(): Promise<boolean> {
    return ((this.yt!.getPlayerState() as any) as Promise<PlayerStates>).then(
      (s) => s === 1,
    );
  }

  destroy() {
    this.yt!.destroy();
  }
}
