import React, { FC } from "react";
import title from "../assets/images/life-group-title.png";
import { classes } from "typestyle";
import { colors, typography } from "@arisechurch/design";
import { em, percent, rem } from "csx";
import { useStyles } from "@tim-smart/react-typestyle";
import { NestedCSSProperties } from "typestyle/lib/types";

export interface ILogoProps {
  className?: string;
  horizontal?: boolean;
  size?: number;
}

export const Logo: FC<ILogoProps> = ({
  className,
  horizontal = false,
  size = 25,
}) => {
  const classNames = useStyles(
    {
      root: {
        ...typography.heading2,
        textAlign: "center",
        width: rem(size),
      },

      rootHorizontal: {
        ...typography.heading2,
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        width: rem(size),
      } as NestedCSSProperties,

      imageWrap: ({ horizontal }) =>
        ({
          flex: 1,
          marginBottom: horizontal ? 0 : em(-0.7),
          marginRight: horizontal ? em(0.1) : 0,
          paddingBottom: percent((627 / 2048) * 100),
          position: "relative",
        } as NestedCSSProperties),

      image: {
        display: "block",
        height: percent(100),
        left: 0,
        position: "absolute",
        top: 0,
        width: percent(100),
      },

      imageHorizonal: {
        display: "block",
        height: percent(100),
        left: 0,
        position: "absolute",
        top: 0,
        width: percent(100),
      },

      online: {
        color: colors.primary.toString(),
        fontSize: rem(size * 0.1),
      },
    },
    { horizontal },
  );

  return (
    <h1
      className={classes(
        className,
        horizontal ? classNames.rootHorizontal : classNames.root,
      )}
    >
      <div style={{ flex: 1 }}>
        <div className={classNames.imageWrap}>
          <img className={classNames.image} src={title} alt="Life Group" />
        </div>
      </div>
      <span className={classNames.online}>Online</span>
    </h1>
  );
};
