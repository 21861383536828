import { FlocksGroup } from "./FlocksGroup";

export interface IRoom
  extends Readonly<{
    id: number;
    uuid: string;
    flocks_group_id: number;
    flocks_group?: FlocksGroup;
    video_state?: IVideoState;
  }> {}

export interface IVideoState
  extends Readonly<{
    url: string | null;
    visible: boolean;
    playing: boolean;
    seek: number;
    timestamp: number;
    show_participants: boolean;
  }> {}

export function roomURL(room: IRoom) {
  const url = new URL(`/rooms/${room.uuid}`, window.location.origin);
  return url.toString();
}
