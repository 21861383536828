import React, { FC, useContext } from "react";
import { useBlocState } from "@bloc-js/react-bloc";
import { VideoPlayerContainer } from "./VideoPlayerContainer";
import { VideoSharingContext } from "../bloc/VideoSharingBloc";
import { VideoSharingOverlay } from "../components/VideoSharingOverlay";

export interface IVideoSharingContainerProps {
  isHost: boolean;
}

export const VideoSharingContainer: FC<IVideoSharingContainerProps> = ({
  isHost,
}) => {
  const bloc = useContext(VideoSharingContext)!;
  const { url, visible, show_participants } = useBlocState(bloc);

  return (
    <VideoSharingOverlay
      enabled={!!url}
      visible={visible}
      showParticipants={show_participants}
    >
      <VideoPlayerContainer isHost={isHost} sharedVideoBloc={bloc} />
    </VideoSharingOverlay>
  );
};
