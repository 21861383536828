import React, { FC, useEffect, useRef } from "react";
import { colors } from "@arisechurch/design";
import { createSocket } from "../socket";
import { fullScreenFlex } from "../styles/layout";
import { HostRoomBloc } from "../bloc/HostRoomBloc";
import { HostToolbarContainer } from "../containers/HostToolbarContainer";
import { percent } from "csx";
import { Socket } from "phoenix";
import { Spinner } from "@arisechurch/design";
import { withBlocs, useBlocState } from "@bloc-js/react-bloc";
import { useStyles } from "@tim-smart/react-typestyle";
import { VideoCallBloc, VideoCallContext } from "../bloc/VideoCallBloc";
import { VideoCallContainer } from "../containers/VideoCallContainer";
import { VideoSharingContainer } from "../containers/VideoSharingContainer";
import {
  VideoSharingBloc,
  VideoSharingContext,
} from "../bloc/VideoSharingBloc";
import { ShareDialogContainer } from "../containers/ShareDialogContainer";
import { ShareDialogContext, ShareDialogBloc } from "../bloc/ShareDialogBloc";
import { RouteComponentProps } from "react-router-dom";

interface IHostRoomProps extends RouteComponentProps<{ groupID: string }> {}

interface IHostRoomBlocs {
  hostRoomBloc: HostRoomBloc;
  shareDialogBloc: ShareDialogBloc;
  videoCallBloc: VideoCallBloc;
  videoSharingBloc: VideoSharingBloc;
}

const HostRoomI: FC<IHostRoomProps & IHostRoomBlocs> = ({
  hostRoomBloc,
  shareDialogBloc,
  videoCallBloc,
  videoSharingBloc,
}) => {
  // Bloc cleanup

  // Room bloc
  const { token, room } = useBlocState(hostRoomBloc);

  // Setup socket
  const socket = useRef<Socket>();
  useEffect(() => {
    if (!token) return;

    const newSocket = createSocket({ token });
    socket.current = newSocket;
    newSocket.connect();

    return () => {
      newSocket.disconnect();
    };
  }, [token, socket]);

  // Video sharing bloc
  useEffect(() => {
    if (!socket.current || !room) return;
    videoSharingBloc.init(socket.current, room.id);
  }, [room, socket, token, videoSharingBloc]);

  // Hook up shareDialogBloc to videoSharingBloc
  useEffect(() => {
    const s = shareDialogBloc.subscribe((state) => {
      if (state.type !== "shared") return;
      videoSharingBloc.share(state.url);
    });
    return () => {
      s.unsubscribe();
    };
  }, [shareDialogBloc, videoSharingBloc]);

  // Styles
  const classNames = useStyles({
    root: {
      ...fullScreenFlex,
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
    },

    video: {
      display: "flex",
      flex: 1,
      position: "relative",
      width: percent(100),
    },
  });

  return (
    <div className={classNames.root}>
      {room && token ? (
        <VideoSharingContext.Provider value={videoSharingBloc}>
          <VideoCallContext.Provider value={videoCallBloc}>
            <ShareDialogContext.Provider value={shareDialogBloc}>
              <ShareDialogContainer />
              <HostToolbarContainer room={room} />
              <div className={classNames.video}>
                <VideoSharingContainer isHost />
                <VideoCallContainer
                  roomName={room.uuid}
                  group={room.flocks_group}
                  interfaceConfig={{
                    DEFAULT_BACKGROUND: colors.text.toString(),
                    TOOLBAR_BUTTONS: [
                      "microphone",
                      "camera",
                      "fullscreen",
                      "fodeviceselection",
                      "hangup",
                      "chat",
                      "settings",
                      "videoquality",
                      "tileview",
                      "help",
                      "mute-everyone",
                    ],
                    SHOW_CHROME_EXTENSION_BANNER: false,
                    SETTINGS_SECTIONS: ["devices", "moderator"],
                  }}
                />
              </div>
            </ShareDialogContext.Provider>
          </VideoCallContext.Provider>
        </VideoSharingContext.Provider>
      ) : (
        <Spinner color={colors.white} />
      )}
    </div>
  );
};

export const HostRoom = withBlocs<IHostRoomProps, IHostRoomBlocs>((props) => {
  const {
    match: {
      params: { groupID },
    },
  } = props;

  const hostRoomBloc = new HostRoomBloc();
  hostRoomBloc.createRoom(groupID === "no_group" ? undefined : +groupID);
  hostRoomBloc.fetchToken();

  const shareDialogBloc = new ShareDialogBloc();
  const videoCallBloc = new VideoCallBloc(true);
  const videoSharingBloc = new VideoSharingBloc(videoCallBloc);

  return {
    hostRoomBloc,
    shareDialogBloc,
    videoCallBloc,
    videoSharingBloc,
  };
})(HostRoomI);
