import { createContext } from "react";
import { Socket } from "phoenix";

export const SocketContext = createContext<Socket | undefined>(undefined);

export function createSocket(params?: object) {
  let url = "/socket";
  if (window.location.host.includes("localhost")) {
    url = "ws://localhost:4000/socket";
  }

  return new Socket(url, {
    params,
  });
}
