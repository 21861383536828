import { Bloc } from "@bloc-js/bloc";
import { SearchResult } from "../models/YoutubeVideo";
import { list } from "../repo/liveVideosRepo";
import { createContext } from "react";

export const LiveVideosContext = createContext<LiveVideosBloc | undefined>(
  undefined,
);

export class LiveVideosBloc extends Bloc<SearchResult[] | undefined> {
  constructor() {
    super(undefined);
  }

  fetch() {
    this.next(undefined);

    list().then(
      (results) => {
        this.next(results);
      },
      (err) => {
        this.next(undefined);
        console.error(err);
      },
    );
  }
}
