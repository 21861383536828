import { NestedCSSProperties } from "typestyle/lib/types";
import { percent } from "csx";

export const fullScreenFlex: NestedCSSProperties = {
  display: "flex",
  height: percent(100),
  overflow: "hidden",
  position: "relative",
  width: percent(100),
};
